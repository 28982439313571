import { graphql } from "gatsby";
import * as React from "react";
import { useState } from "react";
// import { Helmet } from "react-helmet";
import { Tab } from "@headlessui/react";
import Layout from "../../components/layout";
import { DataProvider } from "../../contentful/data-provider";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import TitleSection from "../../components/sections/title-section";
import Seo from "../../components/seo";

const AdspecsPage = ({ data }) => {
	const dataProvider = new DataProvider(data);
	const adSpecs = data.allContentfulAdSpec.edges;
	const [selectedIndex, setSelectedIndex] = useState(0);
	const title = data.allContentfulAdSpec?.edges[0].node.title;

	return (
		<Layout dataProvider={dataProvider}>
			<Seo dataProvider={dataProvider} title={`Glassview | Adspecs - ${title}`} />
			<main className='pt-24 lg:pt-24'>
				<TitleSection content={{ title: "Ad Specs" }} />
				<section className='pt-0 pb-20 products-section' key={`props.key`}>
					<div className={"container"}>
						<Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
							<div className={"flex flex-wrap md:flex-nowrap"}>
								<div className={"basis-full md:basis-3/12 xl:basis-1/5 md:mr-8 xl:mr-16"}>
									<div className={"sticky top-[110px]"}>
										<Tab.List className='tablist tablist-grey'>
											{adSpecs.map((adSpec, index) => (
												<Tab
													className={`listbox-item ${index === selectedIndex && "listbox-active"}`}
													type='button'
													role='tab'
													aria-selected='true'
													key={index}
												>
													<span className={`listbox-selected-item`}>{adSpec.node.title}</span>
												</Tab>
											))}
										</Tab.List>
									</div>
								</div>
								<div className={"basis-full lg:basis-9/12 xl:basis-4/5 pt-10 md:pt-0 overflow-x-auto"}>
									<Tab.Panels>
										{adSpecs.map((adSpec, index) => (
											<Tab.Panel key={index}>
												<div className='adspecs-section'>
													{renderRichText(adSpec.node.body)}
												</div>
											</Tab.Panel>
										))}
									</Tab.Panels>
								</div>
							</div>
						</Tab.Group>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default AdspecsPage;

export const query = graphql`
	query ($locale: String!) {
		allContentfulAdSpec(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					id
					node_locale
					title
					body {
						raw
					}
				}
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
		allContentfulPage(filter: { contentful_id: { eq: "2pobbH3xj61nNV4G9eUqun" }, node_locale: { eq: $locale } }) {
			edges {
				node {
					...CurrentPageDetails
				}
			}
		}
	}
`;
